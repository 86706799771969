<template>
  <section class="modal--container auth--container">
    <div class="title--text" v-if="$mq !== 'xs'">
      {{ $t('loginRegister.login') }}
    </div>
    <SectionLoader :show="isLoading" />
    <social-media-auth @updateLoading="updateLoading" :afterAction="afterAction" />
    <hr class="separator" />
    <my-alert :variant="alertType" :show="showAlert">
      <div v-html="messageAlert"></div>
      <resend-activation v-if="unactivated" :email="email" />
    </my-alert>
    <form @submit.prevent="login" id="loginForm">
      <div class="auth--input mb-3" :class="{ 'has-error': validation.hasError('email') }">
        <v-text-field
          :label="$t('loginRegister.email')"
          outlined
          :hide-details="true"
          v-model="email"
          name="email"
          class="basic--input"
        ></v-text-field>
        <span class="val-error" v-if="validation.hasError('email')">{{
          validation.firstError('email')
        }}</span>
      </div>
      <div class="auth--input mb-3" :class="{ 'has-error': validation.hasError('password') }">
        <v-text-field
          :label="$t('loginRegister.pass')"
          outlined
          :hide-details="true"
          v-model="password"
          name="password"
          class="basic--input"
          :type="showPass ? 'text' : 'password'"
          :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="() => (showPass = !showPass)"
        ></v-text-field>
        <span class="val-error" v-if="validation.hasError('password')">{{
          validation.firstError('password')
        }}</span>
      </div>
      <a href="javascript:;" @click="showModal('modal-forget-pass')" class="auth--link">{{
        $t('loginRegister.forgot')
      }}</a>
      <hr class="separator" />
      <button :disabled="isLoading" type="submit" class="btn btn-primary main--btn">Login</button>
    </form>
    <div class="bottom--part">
      <span class="mr-2">{{ $t('loginRegister.message1') }}</span>
      <a href="javascript:" @click="showModal('modal-register')" class="auth--link">{{
        $t('loginRegister.messageBold1')
      }}</a>
    </div>
  </section>
</template>

<script>
import { Validator } from 'simple-vue-validator';
import MyAlert from '@/components/utils/my-alert.vue';
const SocialMediaAuth = () => import('@/components/auth/social-media-auth');
const ResendActivation = () => import('@/components/auth/resend-activation');
const SectionLoader = () => import('@/components/content-loading/section-loading');

export default {
  data() {
    return {
      email: null,
      password: null,
      isLoading: false,
      remember: false,
      unactivated: false,
      messageAlert: null,
      showAlert: false,
      alertType: '',
      showPass: false,
    };
  },
  props: ['formType', 'dismissable', 'afterAction'],
  components: {
    MyAlert,
    SocialMediaAuth,
    ResendActivation,
    SectionLoader,
  },
  validators: {
    email(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.loginRegister.email.required'))
        .email(this.$i18n.t('errors.loginRegister.email.invalid'))
        .maxLength(255, this.$i18n.t('errors.loginRegister.email.max', { maxChar: 255 }));
    },
    password(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.loginRegister.password.required'))
        .minLength(6, this.$i18n.t('errors.loginRegister.password.min', { minChar: 6 }))
        .maxLength(255, this.$i18n.t('errors.loginRegister.password.max', { maxChar: 255 }));
    },
  },
  methods: {
    async showModal(modalName) {
      this.$modal.hide('modal-login');
      if (modalName === 'modal-forget-pass') {
        this.$modal.show(modalName);
      } else {
        if (this.formType === 'page') {
          this.$router.push('/register');
        } else if (this.formType === 'add-listing') {
          this.$emit('switchAuth');
        } else {
          this.$modal.show(modalName, { dismissable: this.dismissable });
        }
      }
    },
    updateLoading(val) {
      this.isLoading = val;
    },
    async login() {
      try {
        let self = this;
        this.hasError = false;
        this.dataError = null;
        this.isLoading = true;
        const isValid = await this.$validate();
        if (isValid) {
          this.isLoading = true;
          const response = await this.$store
            .dispatch('global/login', {
              fields: {
                email: this.email,
                password: this.password,
                remember: this.remember,
              },
            })
            .catch((e) => {
              self.hasError = true;
              self.dataError = e.response.data.error;
            });
          if (!this.hasError && response.error) {
            this.hasError = true;
            this.dataError = response.error;
          }
          if (!this.hasError) {
            this.isLoading = false;
            if (this.afterAction !== null && this.afterAction !== undefined) {
              this.afterAction('LOGIN_SUCCESS');
            } else {
              this.$router.go(0);
            }
          } else {
            const message =
              this.dataError.type === 'unactivated'
                ? this.dataError.message
                : this.dataError.message;

            const type =
              this.dataError.type === 'unactivated' || this.dataError.type === 'validation'
                ? 'danger'
                : 'success';

            this.unactivated = this.dataError.type === 'unactivated';
            this.showAlert = true;
            this.messageAlert = message;
            this.alertType = type;
            self.isLoading = false;
          }
        }

        // eslint-disable-next-line no-empty
      } catch (e) {
      } finally {
        this.isLoading = false;
      }
    },
  },
  mounted() {
    // this.openModal();
  },
};
</script>
